<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        v-else>
        <v-icon>{{addIcon.icon}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model="name"
          label="Name"
          outlined
          dense
          placeholder="Name"
          :class="formErrorFields.nameErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.nameErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('name', formErrorFields)"
        ></v-text-field>

        <v-text-field
          v-model="email"
          label="Email"
          outlined
          dense
          type="email"
          placeholder="Email"
          :class="formErrorFields.emailErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.emailErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('email', formErrorFields)"
        ></v-text-field>

        <v-text-field
          v-model="username"
          label="Username"
          outlined
          dense
          placeholder="Username"
          :class="formErrorFields.usernameErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.usernameErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('username', formErrorFields)"
        ></v-text-field>

        <v-row class="pb-0 mb-0">
          <v-col cols="12" lg="6" class="mb-0 pb-0">
            <v-text-field
              v-model="password"
              label="Password"
              outlined
              dense
              type="password"
              placeholder="Password"
              :class="formErrorFields.passwordErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.passwordErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('password', formErrorFields)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="mb-0 pb-0">
            <v-text-field
              v-model="confirmPassword"
              label="Confirm Password"
              outlined
              dense
              type="password"
              placeholder="Confirm Password"
              :class="formErrorFields.confirmPasswordErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.confirmPasswordErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('confirmPassword', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-0 pt-0">
          <v-col cols="12" :lg="access == 'PAYROLL' ? 6 : 12" class="pt-0 mt-0">
            <v-autocomplete
              v-model="access"
              :items="['PAYROLL','SCHEDULER']"
              outlined
              dense
              clearable
              label="Access"
              class="mt-0"
              :class="formErrorFields.accessErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.accessErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('access', formErrorFields)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0" v-if="access=='PAYROLL'">
            <v-autocomplete
              v-model="level"
              :items="[2,3,4]"
              outlined
              dense
              clearable
              label="Level"
              class="mt-0"
              :class="formErrorFields.levelErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.levelErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('level', formErrorFields)"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-autocomplete
          v-if="access=='SCHEDULER'"
          v-model="role"
          :items="[
            { value: 'encoder', text: 'ENCODER' },
            { value: 'approver', text: 'APPROVER' },
          ]"
          outlined
          dense
          clearable
          label="Role"
          class="mb-3"
          :error-messages="formErrorFields.roleErrorMessage"
          :disabled="formState===null"
          @change="formError().remove('role', formErrorFields)"
        ></v-autocomplete>

        <v-autocomplete
          v-if="access=='SCHEDULER'"
          v-model="outletAssignment"
          :items="outlets"
          outlined
          dense
          clearable
          multiple
          :loading="outletLoading"
          item-text="title"
          item-value="id"
          label="Outlet Assignment"
          class="mb-3"
          :error-messages="formErrorFields.outletAssignmentErrorMessage"
          :disabled="formState===null"
          @change="formError().remove('outletAssignment', formErrorFields)"
        ></v-autocomplete>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    >
    </snackbar>

  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPlusOutline } from '@mdi/js'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'

export default {
  props: ['data'],
  setup(props, { emit }) {
    const loading = ref(false)
    const outletLoading = ref(false)
    const message = ref('')
    const addIcon = { icon: mdiPlusOutline, name: 'mdiPlusOutline' }
    const name = ref('')
    const email = ref('')
    const username = ref('')
    const password = ref('')
    const confirmPassword = ref('')
    const access = ref('')
    const level = ref('')
    const outletAssignment = ref('')
    const role = ref('')
    const outlets = ref([])
    const formState = ref(null)
    const formErrorFields = ref({
      nameErrorMessage: '',
      emailErrorMessage: '',
      usernameErrorMessage: '',
      passwordErrorMessage: '',
      confirmPasswordErrorMessage: '',
      accessErrorMessage: '',
      levelErrorMessage: '',
      outletAssignmentErrorMessage: '',
      roleErrorMessage: '',
    })

    watch(access, async val => {
      if (val === 'SCHEDULER') {
        await get(`${process.env.VUE_APP_URI}/api/payroll/user-accounts/outlets`, outlets, outletLoading)
      }
    })

    watch(() => props.data, async () => {
      formState.value = 'Edit'

      if (props.data.access === 'SCHEDULER') {
        outletAssignment.value = props.data.outlet_assignment_id !== null ? props.data.outlet_assignment_id.split(',') : []
        outletAssignment.value = outletAssignment.value.map(val => parseInt(val, 10))
        role.value = props.data.role
      } else {
        level.value = props.data.level
      }

      name.value = props.data.name
      email.value = props.data.email
      username.value = props.data.username
      access.value = props.data.access
    })

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formState.value = null
      name.value = ''
      email.value = ''
      username.value = ''
      password.value = ''
      confirmPassword.value = ''
      access.value = ''
      level.value = ''
      outletAssignment.value = ''
      role.value = ''
    }

    const submit = async () => {
      const formData = {
        name: name.value,
        email: email.value,
        username: username.value,
        password: password.value,
        confirmPassword: confirmPassword.value,
        access: access.value,
        level: level.value,
        outletAssignment: outletAssignment.value,
        role: role.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/user-accounts`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/user-accounts/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        emit('updateUniqueID', access.value)
        reset()
      }
    }

    return {
      name,
      email,
      username,
      password,
      confirmPassword,
      access,
      level,
      role,
      outletAssignment,
      outlets,
      outletLoading,
      addIcon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
    }
  },

  components: {
    snackbar,
  },
}
</script>
