<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
      lg="4"
      md="4"
      xl="4"
    >
      <form-view :data="fomrData" @updateUniqueID="updateUniqueID"></form-view>
    </v-col>
    <v-col
      cols="12"
      lg="8"
      md="8"
      xl="8"
    >
      <v-tabs
        color="deep-purple accent-4"
      >
        <v-tabs v-model="accessType">
          <v-tab>Payroll Users</v-tab>
          <v-tab>Scheduler Users</v-tab>
        </v-tabs>

        <v-tabs-items v-model="accessType">
          <v-tab-item>
            <list-view-payroll-user @passData="passData" :uniqueID="uniquePayrollId"></list-view-payroll-user>
          </v-tab-item>
          <v-tab-item>
            <list-view-scheduler-user @passData="passData" :uniqueID="uniqueSchedulerId"></list-view-scheduler-user>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import FormView from './form.vue'
import ListViewPayrollUser from './payroll-users.vue'
import ListViewSchedulerUser from './scheduler-users.vue'

export default {
  metaInfo: {
    title: 'User Account',
  },

  setup() {
    const fomrData = ref({})
    const uniquePayrollId = ref('')
    const uniqueSchedulerId = ref('')
    const accessType = ref('')

    const passData = d => {
      fomrData.value = d
    }

    const updateUniqueID = access => {
      if (access === 'PAYROLL') {
        uniquePayrollId.value = Math.random()
      } else {
        uniqueSchedulerId.value = Math.random()
      }
    }

    return {
      fomrData,
      passData,
      updateUniqueID,
      uniquePayrollId,
      uniqueSchedulerId,
      accessType,
    }
  },

  components: {
    ListViewPayrollUser,
    ListViewSchedulerUser,
    FormView,
  },
}
</script>
