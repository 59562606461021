<template>
  <v-card class="elevation-1">
    <v-card-title>
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          {{icon.edit}}
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="deleteItem(item)"
        >
          {{icon.delete}}
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="setOrViewPermission(item)"
          title="set / view permission"
        >
          {{icon.permission}}
        </v-icon>
        <v-icon
          small
          @click="revokeOrActivate(item)"
          :title="item.status === 'Active' ? 'Revoke' : 'Activate'"
        >
          {{item.status === 'Active' ? icon.revoke : icon.activate}}
        </v-icon>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />

    <permission
      :show="showPermissionDialog"
      :user="permissionDialogUserData"
      :access="'scheduler'"
      @closeDialog="showPermissionDialog=false"></permission>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlusOutline,
  mdiLockOpen,
  mdiLockOff,
  mdiKey,
} from '@mdi/js'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import Permission from './permission.vue'

export default {
  components: {
    snackbar,
    Permission,
  },

  props: ['uniqueID'],

  setup(props, { emit }) {
    const search = ref('')
    const headers = ref([
      { text: 'Name', align: 'start', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Username', value: 'username' },
      { text: 'Role', value: 'role' },
      { text: 'Outlet Assignment', value: 'outlet_assignment' },
      { text: 'Created By', value: 'created_by.name' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Action', value: 'actions' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const icon = {
      add: mdiPlusOutline,
      edit: mdiPencilOutline,
      delete: mdiDeleteOutline,
      revoke: mdiLockOpen,
      activate: mdiLockOff,
      permission: mdiKey,
    }
    const showPermissionDialog = ref(false)
    const permissionDialogUserData = ref(null)

    const {
      update,
      remove,
      responseMessageStatus,
      responseMessage,
    } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/payroll/user-accounts/masterlist/scheduler`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(() => props.uniqueID, () => {
      console.log(props.uniqueID)
      getData(listUri, items, totalItems, loading, options, search)
    })

    const editItem = item => {
      emit('passData', item)
      console.log(item)
    }

    const deleteItem = async item => {
      await remove(`${process.env.VUE_APP_URI}/api/payroll/user-accounts/${item.id}/scheduler`, loading)
      await getData(listUri, items, totalItems, loading, options, search)
    }

    const revokeOrActivate = async item => {
      await update(`${process.env.VUE_APP_URI}/api/payroll/user-accounts/${item.id}/revoked/SCHEDULER`, loading)
      await getData(listUri, items, totalItems, loading, options, search)
    }

    const setOrViewPermission = async item => {
      showPermissionDialog.value = true
      permissionDialogUserData.value = item
    }

    return {
      search,
      headers,
      items,
      searchTable,
      icon,
      options,
      totalItems,
      loading,
      editItem,
      deleteItem,
      revokeOrActivate,
      setOrViewPermission,
      showPermissionDialog,
      permissionDialogUserData,
      snackbar,
      responseMessageStatus,
      responseMessage,
    }
  },
}
</script>
