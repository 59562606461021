<template>
  <div>
    <v-dialog
        v-model="showDialog"
        persistent
        width="400"
      >
      <v-card
        class="mx-auto"
        max-width="400"
        :loading="loadingDialog"
      >
        <v-sheet class="pa-4 primary lighten-2">
          <v-text-field
            v-model="search"
            label="Search Permission (Case Sensitive)"
            dark
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
            autocomplete="off"
            @keypress.enter="searchPermission"
          ></v-text-field>
        </v-sheet>
        <v-virtual-scroll height="400" item-height="50" :items="filteredItems">
          <template v-slot:default="{ item }">
            <v-list-item>
              <v-list-item-action>
                <v-checkbox
                  class="pt-0 mt-0"
                  :value="item.module_id"
                  v-model="selectedPermissions"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.module }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="loading"
            @click="submit">
            Set Permission
          </v-btn>
          <v-btn
            outlined
            class="mx-2"
            @click="close"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    >
    </snackbar>
  </div>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'

export default {
  components: {
    snackbar,
  },

  props: {
    show: Boolean,
    user: Object,
    access: String,
  },

  setup(props, { emit }) {
    const items = ref([])
    const open = ref([1, 2])
    const search = ref(null)
    const caseSensitive = ref(false)
    const showDialog = ref(false)
    const loading = ref(false)
    const user = ref(null)
    const loadingDialog = ref(false)
    const selectedPermissions = ref([])
    const filteredItems = ref([])

    const {
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    watch(() => props.show, async val => {
      showDialog.value = val
      selectedPermissions.value = []

      if (val) {
        user.value = props.user
        await get(`${process.env.VUE_APP_URI}/api/payroll/user-permissions/${props.user.id}/${props.access}`, items, loadingDialog)
        items.value.forEach(item => {
          if (item.active === 1) selectedPermissions.value.push(item.module_id)
        })

        filteredItems.value = items.value
      }
    })

    watch(() => search.value, val => {
      if (val === '') {
        filteredItems.value = items.value
      }
    })

    const close = () => {
      emit('closeDialog', false)
      showDialog.value = false
    }

    const submit = async () => {
      const data = []
      selectedPermissions.value.forEach(item => {
        if (typeof (item) === 'number') data.push(item)
      })

      await update(`${process.env.VUE_APP_URI}/api/payroll/user-permissions/${props.user.id}/${props.access}`, loading, { permission: data }, null)
      close()
    }

    const searchPermission = () => {
      filteredItems.value = items.value.filter(item => item.module.includes(search.value))
    }

    return {
      items,
      open,
      search,
      caseSensitive,
      showDialog,
      loading,
      submit,
      close,
      loadingDialog,
      selectedPermissions,
      responseMessage,
      responseMessageStatus,
      filteredItems,
      searchPermission,
    }
  },
}
</script>
